import { Store } from 'vuex';

import films, { IFilmsState } from './films';
import register, { IRegisterState } from './register';
import user from './user';
import main, { IMainState } from './main';
import filmsCollection, { IFilmsCollectionState } from './filmsCollection';
import { IPublicFilmsState } from './filmsPublic';

export interface IStore {
    films: IFilmsState;
    register: IRegisterState;
    user: IUser;
    main: IMainState;
    publicFilms: IPublicFilmsState;
    filmsCollection: IFilmsCollectionState;
}

const store = new Store<IStore>({
    modules: {
        main,
        user,
        films,
        filmsCollection,
        register
    }
});

export default store;
