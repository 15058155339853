import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { IStore } from '@/store';
import env from '@/config/env';
import {
    combineCheckers,
    combineLoaders,
    isFilmLoaded,
    isPublicFilmsLoaded,
    isUserLoaded,
    loadFilms,
    loadPublicFilms,
    loadUser
} from './loadActions';

export interface IRouterMeta {
    title: string;
    requiresAuth: boolean;
    isMenuItem: boolean;
    icon: string;
    isLoaded(state: IStore): boolean;
    load(state: IStore, to: RouteLocationNormalized): Promise<null>;
    updateState(to: RouteLocationNormalized, state: IStore): void;
}

export interface ICustomRouteRecordRaw {
    meta: IRouterMeta;
}

type TCustomRouteRecordRaw = Omit<RouteRecordRaw, 'meta'> & ICustomRouteRecordRaw;

function updateTitle(to: RouteLocationNormalized, state: IStore) {
    const title = to.meta.title;
    document.title = title ? `${title} - ${env.defaultTitle}` : env.defaultTitle;
}

function updateState(to: RouteLocationNormalized, state: IStore) {
    updateTitle(to, state);
}

const routes: TCustomRouteRecordRaw[] = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/pages/Films.vue'),
        meta: {
            title: 'Главная',
            isLoaded: combineCheckers(isFilmLoaded, isUserLoaded),
            load: combineLoaders(loadFilms, loadUser)
        }
    },
    {
        path: '/newHome',
        name: 'newHome',
        component: () => import('@/pages/Home.vue'),
        meta: {
            title: 'Главная',
            isLoaded: isFilmLoaded,
            load: loadFilms
        }
    },
    {
        path: '/user',
        name: 'User',
        component: () => import('@/pages/User.vue'),
        meta: {
            title: 'Профиль',
            isMenuItem: true,
            icon: 'mdiAccount',
            isLoaded: isUserLoaded,
            load: loadUser
        }
    },
    {
        path: '/user/:username',
        name: 'OtherUser',
        component: () => import('@/pages/OtherUser.vue'),
        meta: {
            title: 'Пользователь'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/pages/About.vue'),
        meta: {
            title: 'О нас',
            isMenuItem: true,
            icon: 'mdiAlertCircleOutline'
        }
    },
    {
        path: '/film/:id',
        name: 'Film',
        component: () => import('@/pages/Film.vue'),
        props: true,
        meta: {
            updateState: (to: RouteLocationNormalized, state: IStore) => {
                const title = state.films.films.find((film) => film.id === to.params.id)?.name || '';
                document.title = title ? `${title} - ${env.defaultTitle}` : env.defaultTitle;
            },
            isLoaded: isFilmLoaded,
            load: loadFilms
        }
    },
    {
        path: '/history/',
        name: 'Timeline',
        component: () => import('@/pages/Timeline.vue'),
        props: true,
        meta: {
            title: 'История',
            isLoaded: isFilmLoaded,
            load: loadFilms,
            isMenuItem: true,
            icon: 'mdiClockOutline'
        }
    },
    {
        path: '/auth/',
        name: 'Auth',
        component: () => import('@/pages/Auth.vue'),
        props: true,
        meta: {
            requiresAuth: false,
            title: 'Авторизация'
        }
    },
    {
        path: '/register/',
        name: 'Register',
        component: () => import('@/pages/Register.vue'),
        props: true,
        meta: {
            requiresAuth: false,
            title: 'Регистрация'
        }
    },
    {
        path: '/share/:data',
        name: 'SharePage',
        component: () => import('@/pages/PublicPage.vue'),
        props: true,
        meta: {
            requiresAuth: false,
            title: '',
            isLoaded: isPublicFilmsLoaded,
            load: loadPublicFilms
        }
    },
    {
        path: '/pin/:rel',
        name: 'Pin',
        component: () => import('@/pages/Pin.vue'),
        props: true,
        meta: {
            requiresAuth: false,
            title: 'Пин'
        }
    },
    {
        path: '/add-pin/',
        name: 'AddPin',
        component: () => import('@/pages/AddPin.vue'),
        props: true,
        meta: {
            requiresAuth: false,
            title: 'Установка ПИН кода'
        }
    },
    {
        path: '/reset-pin/',
        name: 'ResetPin',
        component: () => import('@/pages/ResetPin.vue'),
        props: true,
        meta: {
            requiresAuth: false,
            title: 'Сбросить ПИН код'
        }
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import('@/pages/NotFound.vue'),
        meta: {
            requiresAuth: false,
            title: 'Страница не найдена'
        }
    }
].map((route): TCustomRouteRecordRaw => {
    const meta: IRouterMeta = {
        requiresAuth: route.meta.requiresAuth ?? true,
        title: route.meta.title ?? '',
        isMenuItem: route.meta.isMenuItem ?? false,
        icon: route.meta.icon ?? '',
        updateState: route.meta.updateState ?? updateState,
        isLoaded: route.meta.isLoaded ?? (() => true),
        load: route.meta.load ?? (() => Promise.resolve(null))
    };

    return {...route, meta };
});

export const router = createRouter({
    history: createWebHistory(),
    routes: routes as unknown as RouteRecordRaw[]
});
