import { ApiMethods, HttpMethods } from '@/utility/base';
import { crudQueryNew } from '@/utility/service';

interface ICrud<T> {
    create: (data: T) => Promise<ICrudResult<string>> ;
    read: (data?: T, filter?: object) => Promise<ICrudResult<T[]>> ;
    update: (data: T) => Promise<ICrudResult<void>> ;
    delete: (data: T) => Promise<ICrudResult<void>> ;
}

interface ICrudOptions {
    method: string;
    service?: string;
}

export class Crud<T> implements ICrud<T> {

    protected _service: string;

    protected _method: string;

    constructor({ method, service = 'service' }: ICrudOptions) {
        this._service = service;
        this._method = method;
    }

    create(data: Partial<T>): Promise<ICrudResult<string>> {
        return this._call<string>(data, HttpMethods.Create);
    }

    read(data: Partial<T> = {}, filter?: object): Promise<ICrudResult<T[]>> {
        return this._call<T[]>(data, HttpMethods.Read, filter);
    }

    update(data: Partial<T>): Promise<ICrudResult<void>> {
        return this._call<void>(data, HttpMethods.Update);
    }

    delete(data: Partial<T>): Promise<ICrudResult<void>> {
        return this._call<void>(data, HttpMethods.Delete);
    }

    protected _call<TRes>(data: Partial<T>, method: HttpMethods, filter?: object): Promise<ICrudResult<TRes>> {
        const patchedData = this._patchData(data, filter);
        const result = crudQueryNew<TRes>(patchedData, method, ApiMethods.Service, this._service);

        return result.then((res: IResponseNew<TRes>) => {
            return {
                ...res,
                error: res.errors?.length > 0
            };
        });
    }

    protected _patchData(data: Partial<T>, filter?: object): Partial<T> {
        return { ...data, method: this._method, ...(filter ? { filter } : {}) };
    }
}
