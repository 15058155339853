import store from '@/store';
import { router } from './routes';
import './beforeEach';

store.subscribe((mutation, state) => {
    const route = router.currentRoute.value;
    if (typeof route.meta.updateState === 'function') {
        route.meta.updateState(route, state);
    }
});

export default router;
