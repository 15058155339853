import { EventBus } from './eventBus';
import env from '@/config/env';

const keyboardChannelName = 'keyboardEvents';

class Keyboard {
    static currentHeight = window.innerHeight;

    static addEventListener(callback: IEventBusCallback): void {
        EventBus.subscribe(keyboardChannelName, callback);
    }

    static removeEventListener(callback: IEventBusCallback): void {
        EventBus.unsubscribe(keyboardChannelName, callback);
    }
};

if (env.isMobile) {

    window.addEventListener('resize', () => {
        // innerHeight not changed when keyboard opening. https://chromestatus.com/feature/5656077370654720
        const height = window.visualViewport?.height || window.innerHeight;
        const difference = Math.abs(Keyboard.currentHeight - height);
        const ratio = Keyboard.currentHeight / height;

        if (difference / height > 0.2) {
            if (ratio > 1) {
                EventBus.notify(keyboardChannelName, ['keyboardOpened', { height }]);
            } else {
                EventBus.notify(keyboardChannelName, ['keyboardClosed', { height }]);
            }
        }

        Keyboard.currentHeight = height;
    });
}

export default Keyboard;
