import { EventBus } from "@/utility/functions/eventBus";

interface IIndexes {
    [key: string]: number;
}

export interface ICollectionChanges<IItem, IKey extends string> {
    create: (WithProperty<IKey, IItem> & IItem)[];
    update: (WithProperty<IKey, IItem> & IItem)[];
    delete: {
        [x: string]: string | number;
    }[];
}

export enum CollectionEvents {
    change = 'change',
    create = 'create',
    delete = 'delete',
    update = 'update'
}

export class Collection<IItem, IKey extends string> {

    private _data: (WithProperty<IKey, IItem> & IItem)[] = [];

    private _indexes: IIndexes = {};

    private _keyProperty: IKey = 'id' as IKey;

    private _instanceId: string;

    private _changed = {
        create: [] as (string | number)[],
        update: [] as (string | number)[],
        delete: [] as (string | number)[]
    };

    private _events = {
        create: CollectionEvents.create,
        update: CollectionEvents.update,
        delete: CollectionEvents.delete,
        change: CollectionEvents.change
    };

    constructor(options: { keyProperty?: IKey, data?: (WithProperty<IKey, IItem> & IItem)[] } = {}) {
        const { keyProperty, data } = options;
        this.setKeyProperty(keyProperty);
        (data || []).forEach((item) => this.add(item));
        this._instanceId = this._generateInstanceId();
    }

    setKeyProperty(key: IKey | void): void {
        this._keyProperty = key || this._keyProperty;
    }

    getKeyProperty(): string {
        return this._keyProperty;
    }

    add(item: WithProperty<IKey, IItem> & IItem): void {
        const key = this._checkKey(item);
        if (this._indexes[key] > -1) {
            throw new Error(`"item" with key "${key}" already exists.`);
        }
        this._indexes[key] = this._data.length;
        this._data.push(item);
        this._addKeyToChanged(key, CollectionEvents.create);
    }

    at(index: number): WithProperty<IKey, IItem> & IItem {
        const item = this._data[index];
        return item ? { ...item } : item;
    }

    merge(items: (WithProperty<IKey, IItem> & IItem)[] | Collection<IItem, IKey>): void {
        if (!Array.isArray(items) && !(items instanceof Collection)) {
            throw new Error(`"items" was a Array or Collection type.`);
        }
        this._merge(items);
    }

    count(): number {
        return this._data.length;
    }

    getItemByKey(key: string | number): WithProperty<IKey, IItem> & IItem {
        const index: number = this._indexes[key];
        return this._data[index];
    }

    removeItem(item: WithProperty<IKey, IItem> & IItem): void {
        const key = this._checkKey(item);
        const index = this._indexes[key];
        if (typeof index !== 'number') {
            throw new Error(`item not find.`);
        }
        this._data.splice(index, 1);
        this._reIndex();
        this._addKeyToChanged(key, CollectionEvents.delete);
    }

    clone(): Collection<IItem, IKey> {
        const clone = new Collection<IItem, IKey>({ keyProperty: this._keyProperty });
        clone._data = this.getData();
        clone._reIndex();
        return clone;
    }

    clear(): void {
        const keys = this._data.map((item) => item[this._keyProperty]);
        keys.forEach((key) => this.removeItem(this.getItemByKey(key)));
    }

    getChanges() {
        return {
            create: [...this._changed.create],
            update: [...this._changed.update],
            delete: [...this._changed.delete]
        };
    }

    getChangesData(): ICollectionChanges<IItem, IKey> {
        return {
            create: this._changed.create.map((key) => this.getItemByKey(key)),
            update: this._changed.update.map((key) => this.getItemByKey(key)),
            delete: this._changed.delete.map((key) => ({[this._keyProperty]: key})),
        };
    }

    hasChanged(): boolean {
        return this._changed.create.length + this._changed.update.length + this._changed.delete.length > 0;
    }

    acceptChanges(key?: string | number): void {
        if (key) {
            clearKey(key, this._changed.update);
            clearKey(key, this._changed.delete)
            clearKey(key, this._changed.create);
        } else {
            this._changed = {
                create: [] as (string | number)[],
                update: [] as (string | number)[],
                delete: [] as (string | number)[]
            };
        }
    }

    getData(): (WithProperty<IKey, IItem> & IItem)[] {
        return [...this._data];
    }

    forEach(callback: (item: WithProperty<IKey, IItem> & IItem, index: number) => void): void {
        this._data.forEach(callback);
    }

    reduce(callback: (acc: any, item: WithProperty<IKey, IItem> & IItem, index: number) => void, initialValue: any): any {
        return this._data.reduce(callback, initialValue);
    }

    every(callback: (item: WithProperty<IKey, IItem> & IItem, index: number) => void): boolean {
        return this._data.every(callback);
    }

    some(callback: (item: WithProperty<IKey, IItem> & IItem, index: number) => void): boolean {
        return this._data.every(callback);
    }

    map(callback: (item: WithProperty<IKey, IItem> & IItem, index: number) => void): boolean {
        return this._data.every(callback);
    }

    subscribe(eventName: CollectionEvents, callBack: IEventBusCallback): void {
        if (this._events[eventName]) {
            EventBus.subscribe(eventName + this._instanceId, callBack);
        }
    }

    unsubscribe(eventName: CollectionEvents, callBack: IEventBusCallback): void {
        if (this._events[eventName]) {
            EventBus.unsubscribe(eventName + this._instanceId, callBack);
        }
    }

    private _generateInstanceId(): string {
        return `${this._keyProperty}-${new Date().getTime()}-${Math.random()}`;
    }

    private _notify(eventName: CollectionEvents, data?: any): void {
        if (this._events[eventName]) {
            const args = data ? [data] : [];
            EventBus.notify(eventName + this._instanceId, args);
        }
    }

    private _merge(items: (WithProperty<IKey, IItem> & IItem)[] | Collection<IItem, IKey>): void {
        items.forEach((item) => this._mergeItem(item));
    }

    private _mergeItem(item: WithProperty<IKey, IItem> & IItem): void {
        const key = this._checkKey(item);
        const index = this._indexes[key];
        if (typeof index === 'number') {
            this._data[index] = item;
        } else {
            this.add(item);
        }
    }

    private _checkKey(item: WithProperty<IKey, IItem> & IItem): string | number {
        const key = item[this._keyProperty];
        if (typeof key !== 'number' && typeof key !== 'string') {
            throw new Error('The "key" was a string or number type.');
        }
        return key;
    }

    private _reIndex(): void {
        this._indexes = this._data.reduce((indexes: IIndexes, item, index: number) => {
            const key: string = this._checkKey(item).toString();
            indexes[key] = index;
            return indexes;
        }, {});
    }

    private _addKeyToChanged(key: string | number, operation: CollectionEvents): void {
        clearKey(key, this._changed.update);
        const wasDeleted = clearKey(key, this._changed.delete)
        const wasCreated = clearKey(key, this._changed.create);

        switch(operation) {
            case CollectionEvents.delete:
                if (!wasCreated) {
                    this._changed.delete.push(key);
                }
                break;
            case CollectionEvents.create:
                if (wasDeleted) {
                    this._changed.update.push(key);
                } else {
                    this._changed.create.push(key);
                }
                break;
            case CollectionEvents.update:
                this._changed.update.push(key);
                break;
        }

        const item = this.getItemByKey(key);
        this._notify(operation, item);
        this._notify(CollectionEvents.change, this.getData());
    }

    toString(): string {
        return `Collection<any, ${this._keyProperty}>`;
    }

    *[Symbol.iterator]() {
        for (let i = 0; i < this._data.length; i++) {
            yield this._data[i] ? { ...this._data[i] } : this._data;
        }
    }
}

function clearKey(key: string | number, arr: (string | number)[]): boolean  {
    const index = arr.findIndex((item) => item == key);
    if (index > -1) {
        arr.splice(index, 1);
        return true;
    }
    return false;
}
