import moment from 'moment-mini';

export function getCurrentDateTimeStr(): string {
    return moment(Date.now()).format('YYYY-MM-DDTHH:mm');
}

export function getCurrentDateTimeStrForServer(): string {
    return moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
}

export function dateToStr(value: Date | string): string {
    return moment(String(value)).format('DD.MM.YYYY');
}

export function dateTimeToStr(value: Date | string): string {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
}

export function dateToOrder(value: Date | string | undefined): number {
    return value ? Math.floor(moment(String(value)).valueOf() / 1000) : 0;
}

export function toWeekEnd(date: Date | moment.Moment): moment.Moment {
    const currentDate = moment(date);
    const offset = 6 - currentDate.day();
    currentDate.add(offset, 'days');
    return currentDate;
}

export function toWeekStart(date: Date | moment.Moment): moment.Moment {
    const currentDate = moment(date);
    const offset = currentDate.day();
    currentDate.subtract(offset, 'days');
    return currentDate;
}

export function dayOfYear(): number {
    const now = new Date();
    var j1= new Date();
    j1.setMonth(0, 0);
    // @ts-ignore
    return Math.round((now - j1) / 8.64e7);
}