const values: IAny = {};

export interface IStateStrategy {
    getItem: (region: string) => string;
    removeItem: (region: string) => void;
    setItem: (region: string, data: string, key: string, value: string) => void;
}

type TStorage = boolean | IStateStrategy;

interface ICookieStrategyConfig {
    exp?: number;
}

function getStateKey(region: string) {
    return `fs.${region}`;
}

function getStorage(storage: TStorage): IStateStrategy {
    return typeof storage === 'boolean' ? new LocalStorageStrategy() : storage;
}

function getState(storage: IStateStrategy, region: string): IAny {
    const key = getStateKey(region);
    const strData: string | null = storage.getItem(key);
    const data: IAny = strData && JSON.parse(strData) || {};
    return data;
}

export function getStateValue<T>(region: string, key: string): T {
    const data = values.hasOwnProperty(region) ? values[region] || {} : getState(new LocalStorageStrategy(), region);
    return data[key];
}

export function resetState(region: string, storage: TStorage = true): void {
    const store = getStorage(storage);
    const key = getStateKey(region);
    if (storage) {
        store.removeItem(key);
    } else {
        delete values[region];
    }
}

/**
 * @description Класс для сохранения настроек.
 * @param defaultState - Значения по умолчанию которые находятся в стейте.
 * @param region - Область видимости сохраняемых значений.
 * @param storage - Признак того нужно ли сохранять значения в localStorage.
 * @returns 
 */
export const State = <TState = IAny>(defaultState: IAny = {}, region: string = 'default', storage: TStorage = true): TState => {

    const store = getStorage(storage);

    const state = {
        ...defaultState,
        ...((storage ? getState(store, region) : values[region] ) || {})
    };

    const save = (target: IAny, prop: string, value: IAny) => {
        if (storage) {
            store.setItem(getStateKey(region), JSON.stringify(target), prop, JSON.stringify(value));
        } else {
            values[region] = target;
        }
    };

    return new Proxy(state, {
        get(target: IAny, prop: string): any {
            return target[prop];
        },
        set(target: IAny, prop: string, val: any): boolean {
            if (/^__/.test(prop)) {
                return false;
            }

            target[prop] = val;
            save(target, prop, val);
            return true;
        },
        deleteProperty(target: IAny, prop: string): boolean {
            if (prop in target) {
                delete target[prop];
                save(target, prop, undefined as unknown as IAny);
            }
            return true;
        }
    });
}

export class CookieStrategy implements IStateStrategy {

    private _exp: number = 0;

    constructor(config: ICookieStrategyConfig = {}) {
        this._exp = config.exp || 0;
    }

    getItem(region: string): string {
        return this._getCookie(region);
    }

    setItem(region: string, data: string): void {
        this._setCookie(region, data, this._exp);
    }

    removeItem(region: string): void {
        document.cookie = region + '=; Max-Age=0';
    }

    private _getCookie(name: string): string {
        const escape = (s: string) => s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : '';
    }

    private _setCookie(key: string, value: string, exp: number = 0): void {
        if (exp > 0) {
            const now = new Date();
            const time = now.getTime();
            const expireTime = time + exp;
            now.setTime(expireTime);
            document.cookie = `${key}=${value};expires=${now.toUTCString()}`;
        } else {
            document.cookie = `${key}=${value}`;
        }
    }
}

export class LocalStorageStrategy implements IStateStrategy {
    getItem(region: string): string {
        return window.localStorage.getItem(region) || '';
    }
    
    setItem(region: string, data: string): void {
        window.localStorage.setItem(region, data);
    }

    removeItem(region: string): void {
        window.localStorage.removeItem(region);
    }
}

export class SessionStorageStrategy implements IStateStrategy {
    getItem(region: string): string {
        return window.sessionStorage.getItem(region) || '';
    }

    setItem(region: string, data: string): void {
        window.sessionStorage.setItem(region, data);
    }

    removeItem(region: string): void {
        window.sessionStorage.removeItem(region);
    }
}
