import { guid } from '@/utility/base/guid';
import { decode, encode } from './pin';
import { SessionState, pinSession, sessions, sessionEach, ISessionItem, ISessionData, ISessionsKeys, SessionType } from './sessionState';

class Session {
    private _current: SessionState;

    get currentId(): string {
        return this._current.get().id;
    }

    constructor() {
        if (!sessions.current) {
            sessions.current = guid();
        }
        this._current = new SessionState(sessions.current);
        this._removeEmptySessions();
    }

    isAuth() {
        const data = this._current.get();
        return Boolean(data.token || data.tokenEncode);
    }

    getToken(): string {
        const data = this._current.get();
        if (data.token) {
            return data.token;
        }
        if (pinSession.token && pinSession.id === sessions.current) {
            return pinSession.token;
        }

        return '';
    }

    switch(id: string) {
        sessions.current = id;
        pinSession.token = '',
        pinSession.id = '',
        window.location.replace('/');
    }

    getAll(): ISessionItem[] {
        const res: ISessionItem[] = []
        sessionEach((item: ISessionsKeys, storage: ISessionData) => {
            if (storage[item.id]) {
                res.push(storage[item.id])
            }
        });
        return res;
    }

    remove(): Promise<IResponse> {
        this._current.set({ token: '', tokenEncode: '' });
        sessions.current = guid();
        this._removeEmptySessions();
        return Promise.resolve({ error: false });
    }

    add({ token, remember = true, username }: { token: string, remember: boolean, username: string }): void {
        // remove old session for this user
        sessionEach((item: ISessionsKeys, storage: ISessionData) => {
            if (storage[item.id]?.username === username) {
                delete storage[item.id];
            }
        });

        const type = remember ? SessionType.Long : SessionType.Short;
        sessions.current = guid();
        sessions.items.push({ type, id: sessions.current });
        this._current = new SessionState(sessions.current);
        this._current.setType(type);
        this._current.set({ token, id: sessions.current, type, username });
        this._removeEmptySessions();
        import('@/store').then((module) => {
            module.default.dispatch('user/load');
            module.default.dispatch('films/load');
        });
    }

    hasPin(): boolean {
        return Boolean(this._current.get().tokenEncode);
    }

    needPin(): boolean {
        return this.hasPin() && !this.getToken();
    }

    async addPin(password: string): Promise<void> {
        const token = JSON.stringify({ token: this.getToken() });
        this._current.set({
            token: '',
            tokenEncode: await encode(token, password)
        });
    }

    async enterPin(password: string): Promise<boolean> {
        const data = this._current.get();
        if (data.tokenEncode) {
            try {
                const tokenStr = await decode(data.tokenEncode, password);
                const { token } = JSON.parse(tokenStr);
                if (token) {
                    pinSession.token = token;
                    pinSession.id = data.id;
                    return true;
                }
                return false;
            } catch (error) {
                return false;
            }
        }
        return false;
    }

    async resetPin(password: string): Promise<boolean> {
        const data = this._current.get();
        if (data.tokenEncode) {
            try {
                const tokenStr = await decode(data.tokenEncode, password);
                const { token } = JSON.parse(tokenStr);
                if (token) {
                    pinSession.token = '';
                    pinSession.id = '';
                    this._current.set({ token: token, tokenEncode: ''});
                    return true;
                }
                return false;
            } catch (error) {
                return false;
            }
        }
        return false;
    }

    updateSessionData(user: IUser) {
        const session = this._current.get()
        if(session.username === user.login && session.name !== user.name) {
            this._current.set({ name: user.name });
        }
    }

    private _removeEmptySessions(): void {
        const saved: ISessionsKeys[] = [];
        const isEmpty = (current: ISessionItem) => !current || !(current.token || current.tokenEncode);

        sessionEach((item: ISessionsKeys, storage: ISessionData) => {
            if (isEmpty(storage[item.id])) {
                delete storage[item.id];
            } else {
                saved.push(item);
            }
        });
        sessions.items = saved;
    }
}

export const session =  new Session();
