<script lang="ts" setup>
    const emit = defineEmits(['close']);
    defineProps({
        headerVisible: {
            type: Boolean,
            default: true
        }
    });

    const close = (event: Event) => {
        event.stopImmediatePropagation();
        emit('close');
    };
</script>

<template>
    <div class="dialog tw-overflow-hidden">
        <div v-if="headerVisible" class="header tw-flex bg-header">
            <div>
                <slot name="header" />
            </div>
            <div class="tw-ml-auto">
                <button-rich @click="close" size="l" class="tw-pt-4 tw-pb-4" icon="mdiClose" color="default"/>
            </div>
        </div>
        <div class="bg-default">

            <slot @close="close"/>
        </div>
    </div>
</template>

<style lang="less" scoped>
    .dialog {
        border-radius: var(--border-radius);
        color: var(--text-default);
    }
</style>
