<script lang="ts" setup>
    import { useStore } from 'vuex';
    import { computed, ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router'
    import { session } from '@/utility/auth/session';
    import { ISessionItem } from '@/utility/auth/sessionState';
    import Menu, { IMenuItem } from '@/components/Menu.vue';

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const users= ref<ISessionItem[]>([]);

    const isAuth = computed(() => store.state.main.isAuth);

    const updateUsers = () => {
        users.value = session.getAll().filter((user) => user.username !== store.state.user.login);
    };
    watch(store.state.user.login, updateUsers);

    updateUsers();

    const switchUser = (user: ISessionItem) => session.switch(user.id);
    const routeNavigate = (name: string) => router.push({ name });
    const quitAppHandler = () => {
        session.remove().then((res) => {
            if (!res.error) {
                document.location.reload();
            }
        });
    };

    const items = computed((): IMenuItem[] => {
        if (isAuth.value) {
            return [
                ...router.getRoutes()
                    .filter(({meta, name}) => meta.isMenuItem && name !== route.name)
                    .map(({meta, name}): IMenuItem => ({
                        id: name,
                        title: meta.title,
                        icon: meta.icon,
                        handler: () => routeNavigate(name)
                    })
                ),
                {
                    id: 'auth',
                    title: 'Сменить аккаунт',
                    icon: '',
                    handler: () => routeNavigate('Auth')
                },
                ...users.value.map((item): IMenuItem => ({
                    id: `user-${item.username}`,
                    title: item.username,
                    align: 'right',
                    handler: () => switchUser(item)
                })),
                {
                    id: 'quit',
                    title: 'Выход',
                    icon: 'mdiLogoutVariant',
                    handler: quitAppHandler
                }
            ];
        }
        return [{
            id: 'login',
            title: 'Войти',
            handler: () => routeNavigate('Auth')
        }];
    });

</script>

<template>
    <div class="tw-flex tw-h-full tw-items-center tw-ml-10 tw-mr-10" >
        <Menu :items="items" :icon="isAuth ? 'mdiAccount' : 'mdiLogin'" size="xl"/>
    </div>
</template>
