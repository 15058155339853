<script lang="ts" setup>
    import { PropType, computed  } from 'vue';
    import { IconPath, IconSize, IconType } from '@/utility/base/icons';

    const props = defineProps({
        icon: {
            type: String as PropType<IconType>
        },
        size: {
            type: String as PropType<IconSize>
        },
        title: {
            type: String
        },
        clickable: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        colorStyle: {
            type: String
        }
    });

    const classes = computed(() => {
        return {
            'icon-clickable': props.clickable !== false,
            ['icon-size-' + (props.size || 'xl')]: true,
            [`text-for-${props.disabled ? 'readonly' : props.colorStyle || 'default'}`]: true,
            [`text-for-${props.colorStyle || 'default'}-hovered`]: props.clickable !== false && !props.disabled
        };
    });

    const src = computed(() => {
        const icon: IconType = props.icon as IconType;

        if (IconPath[icon]) {
            return IconPath[icon];
        } else {
            const emptyIcon = 'mdiAlertCircleOutline';
            console.error(`Icon error: icon with name "${icon}" does not exist in mdi icons`);
            return IconPath[emptyIcon];
        }
    });

</script>

<template>
    <svg
        viewBox="0 0 24 24"
        class="icon tw-transition-color"
        :class="classes"
        :icon-key="icon"
    >
        <title>{{title}}</title>
        <path :d="src" />
    </svg>
</template>

<style lang="less">
.icon {

    &-clickable {
        cursor: pointer;
    }

    &-size {
        &-2xs {
            width: 10px;
            height: 10px;
        }
        &-xs {
            width: 12px;
            height: 12px;
        }
        &-s {
            width: 14px;
            height: 14px;
        }
        &-m {
            width: 16px;
            height: 16px;
        }
        &-l {
            width: 20px;
            height: 20px;
        }
        &-xl {
            width: 24px;
            height: 24px;
        }
        &-2xl {
            width: 32px;
            height: 32px;
        }
        &-3xl {
            width: 36px;
            height: 36px;
        }
        &-4xl {
            width: 40px;
            height: 40px;
        }
        &-5xl {
            width: 48px;
            height: 48px;
        }
        &-6xl {
            width: 56px;
            height: 56px;
        }
        &-7xl {
            width: 64px;
            height: 64px;
        }
        &-8xl {
            width: 72px;
            height: 72px;
        }
    }
}
</style>
