interface IChannels {
    [key: string]: IEventBusCallback[];
}

const channels: IChannels = {};

export class EventBus {
    static subscribe(channel: string, callback: IEventBusCallback): void {
        channels[channel] = channels[channel] || [];

        if (!channels[channel].find(callback)) {
            channels[channel].push(callback);
        }
    }

    static unsubscribe(channel: string, callback: IEventBusCallback): void {
        if (channels[channel]) {
            channels[channel] = channels[channel].filter((func) => func !== callback);
        }
    }

    static notify(channel: string, args: any[] = []) {
        if (channels[channel]) {
            channels[channel].forEach((func: IEventBusCallback) => {
                func(...args);
            });
        }
    }
};
