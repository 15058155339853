import { translate } from './functions';

interface ISearchData {
    strongSearch: string[];
    search: string[];
}

const MIN_MATCH_LENGTH = 2;
const cache = new Map<string, ISearchData>();

export function search(searchString: string, text: string, regex: boolean = false): boolean {
    if (searchString) {
        try {
            if (regex) {
                return searchRegex(searchString, text);
            }
            return searchText(calcSearchData(searchString), normalizeString(text));
        } catch (error) {
            return false;
        }
    }
    return true;
}

function searchText(searchData: ISearchData, searchTarget: string): boolean {
    const words = searchData.strongSearch.length ? searchData.strongSearch : searchData.search;
    const strongMode: boolean = searchData.strongSearch.length > 0;
    let matching = false;

    for (let i = 0; i < words.length; i += 2) {
        const word = words[i];
        const wordTranslated = words[i + 1];
        if (word.length > MIN_MATCH_LENGTH) {
            matching = searchTarget.indexOf(word) !== -1 || searchTarget.indexOf(wordTranslated) !== -1;
        } else {
            matching = !!searchTarget.split(' ').find((value) => value === word || value === wordTranslated);
        }

        if (matching) {
            if (!strongMode) {
                return true;
            }
        } else {
            if (strongMode) {
                return false;
            }
        }
    }

    return matching;
}

function searchRegex(searchValue: string, searchTarget: string): boolean {
    return searchTarget.search(searchValue) !== -1;
}

function normalizeString(text: string): string {
    return text.toLowerCase().trim().replace(/\W/g, (v): string => {
        if (v === 'ё') {
            return 'е'
        }

        return v !== v.toUpperCase() ? v : ' ';
    }).replace(/\s+/g, ' ');
}

function calcSearchData(str: string): ISearchData {

    const valueFromCache = cache.get(str);
    if (valueFromCache) {
        return valueFromCache;
    }

    const strongSearch: string[] = [];
    const search: string[] = [];
    let opened = false;
    let value = '';

    const addValue = (str: string) => {
        if (str) {
            if (opened) {
                strongSearch.push(normalizeString(str));
                strongSearch.push(normalizeString(translate(str)));
            } else {
                search.push(normalizeString(str));
                search.push(normalizeString(translate(str)));
            }
        }
    };

    for (let i = 0; i < str.length; i++) {
        const current = str[i];
        if (current == '"' || current == ' ') {
            addValue(value);
            value = '';
            opened = current == '"' ? !opened : opened;
        } else {
            value += current
        }
    }

    if (value) {
        search.push(normalizeString(value));
        search.push(normalizeString(translate(value)));
    }

    const res: ISearchData = { strongSearch, search };
    cache.set(str, res);
    setTimeout(() => cache.delete(str), 0);
    return res;
}
