import { MutationTree, ActionTree } from 'vuex';
import { updateViewsList } from '@/utility/service';
import { ICollectionChanges } from '@/utility/collection';
import { FilmCurd } from '@/service/services';

export interface IFilmsState {
    films: IFilm[];
    isInitiated: boolean;
}

class State implements IFilmsState {
    // TODO: rename films
    films: IFilm[] = [];
    isInitiated = false;
}

const mutations = <MutationTree<State>>{
    load(state: IFilmsState, films: IFilm[]) {
        state.films = films;
        state.isInitiated = true;
    }
};

const actions = <ActionTree<State, any>>{
    load(context) {
        return FilmCurd.read().then((res) => {
            if (!res.error) {
                context.commit('load', res.result);
            }
        });
    },
    loadIfNeed(context) {
        if (!context.state.isInitiated) {
            return context.dispatch('load');
        }
    },
    saveFilm(context, { film, views }: {film: IFilm, views: ICollectionChanges<IView, 'film'>}) {

        const updatedFilms: Promise<IResponse> = film ? FilmCurd.update(film) : Promise.resolve({
            error: false
        });

        return updatedFilms.then((res) => {
            return views && !res.error ? updateViewsList(views) : res;
        }).then((res) => {
            if (!res.error) {
                return context.dispatch('load');
            }
        });
    },
    deleteFilm(context, data: IFilm) {
        return FilmCurd.delete({ id: data.id }).then((res) => {
            if (!res.error) {
                context.dispatch('load');
            }
        });
    },
    addFilm(context, { film, views }: {film: IFilm, views: ICollectionChanges<IView, 'film'>}) {
        return FilmCurd.create(film).then((res) => {
            if (!views || res.error) {
                return res;
            }
            const filmId = res.result
            views.create.forEach((film) => film.film = filmId);

            return updateViewsList(views);
        }).then((res) => {
            if (!res.error) {
                context.dispatch('load');
            }
        });
    }
};

export default {
    namespaced: true,
    state: new State(),
    mutations: mutations,
    actions: actions
};
