<script lang="ts" setup>
    const emit = defineEmits(['click']);
    const onKeyup = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
            event.stopPropagation();
            emit('click');
        }
    };
</script>

<template>
    <div :tabindex="0" @keyup="onKeyup" @click.stop="emit('click')" class="focus-area">
        <slot/>
    </div>
</template>

<style lang="less" scoped>
    .focus-area:focus-visible {
        outline: none;
        box-shadow: 0 0 5px 2px rgba(21, 156, 228, 0.8);
    }
</style>
