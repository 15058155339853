const numberKeys = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0 };
const keys = {
    ...numberKeys,
    Backspace: 0,
    Delete: 0,
    ArrowLeft: 0,
    ArrowRight: 0,
    ArrowUp: 0,
    ArrowDown: 0,
    Home: 0,
    End: 0,
    Escape: 0,
    Enter: 0,
    Control: 0,
    Shift: 0,
    Alt: 0
};

/**
 * @description Почему то не остановка поведения по умолчанию не работает для мобилок
 * @param { KeyboardEvent } event
 * @returns { boolean }
 */
export function onlyNumberInput(event: KeyboardEvent): boolean {
    const { key, code }= event;
    if (!(key in keys || code in keys)) {
        event.preventDefault();
        return false;
    }
    return true;
}
