import { MutationTree, ActionTree } from 'vuex';
import router from '@/router';
import { session } from '@/utility/auth/session';

export interface IMainState {
    isAuth: boolean;
}

class State implements IMainState {
    isAuth = session.isAuth();
}

const mutations = <MutationTree<State>>{
    authStatus(state: IMainState, isAuth: boolean) {
        state.isAuth = isAuth;
    }
};

const actions = <ActionTree<State, any>>{
    login(context) {
        router.push('/auth/');
        context.commit('authStatus', false);
    },
    auth(context) {
        context.commit('authStatus', true);
    }
};

export default {
    namespaced: true,
    state: new State(),
    mutations: mutations,
    actions: actions
};
