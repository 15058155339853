<script lang="ts" setup>
    import { PropType, ref } from 'vue';
    import Dialog from '@/components/core/Dialog.vue';
    import Popup from '@/components/core/Popup.vue';
    import { IconSize } from '@/utility/base/icons';
    import { ButtonStyle } from '@/utility/base';

    type Align = 'left' | 'right';

    export interface IMenuItem {
        id: string;
        title: string;
        handler: (item: IMenuItem) => void;
        icon?: string;
        align?: string
        multiline?: boolean;
    }

    const popupRef = ref<IPopup>(null as unknown as IPopup);
    const containerRef = ref(null);
    const getPopup = (): IPopup => popupRef.value;

    const props = defineProps({
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        size: {
            type: String as PropType<IconSize>
        },
        buttonStyle: {
            type: String as PropType<ButtonStyle>
        },
        items: {
            type: Array as PropType<IMenuItem[]>,
            default: []
        },
        textAlign: {
            type: String as PropType<Align>,
            default: 'left'
        }
    });

    const itemClick = (item: IMenuItem) => {
        item.handler(item);
        getPopup()?.close();
    };

    const open = () => {
        if (props.items.length > 1) {
            if (!getPopup().open) {
                getPopup().show({ target: containerRef.value as unknown as HTMLElement });
            }
        } else if (props.items) {
            itemClick(props.items[0]);
        }
    };

</script>

<template>
    <div ref="containerRef">
        <button-rich @click="open" :icon="icon" :buttonStyle="buttonStyle" :caption="title" :size="size" />
        <Popup ref="popupRef" v-if="items.length > 1" size="auto" :closeOnOutsideClick="true">
            <Dialog :headerVisible="false" class="dialog">
                <div @click.stop="itemClick(item)" v-for="item in items" :key="item.id" class="item tw-p-8 tw-cursor-pointer">
                    <focus-area @click="itemClick(item)" class="tw-w-full tw-flex">
                        <div class="title tw-flex-grow" :class="`${item.align || ''} ${item.multiline ? '' : 'no-break'}`" >{{ item.title }}</div>
                        <mdi-icon v-if="item.icon" :icon="item.icon" size="xl" class="tw-ml-8"/>
                    </focus-area>
                </div>
            </Dialog>
        </Popup>
    </div>
</template>

<style scoped lang="less">
    .right {
        text-align: right;
    }

    .no-break {
        white-space: nowrap;
    }

    .item {
        background-color: var(--bg-default);

        &:hover {
            background-color: var(--bg-default-hovered);
        }
    }

    .dialog {
        color: var(--text-default);
    }
</style>
