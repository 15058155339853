import { LocalStorageStrategy, SessionStorageStrategy, State } from '@/utility/state';

export enum SessionType {
    Long,
    Short
}

export interface ISessionItem {
    id: string;
    token: string;
    name: string;
    username: string;
    type: SessionType;
    tokenEncode?: string;
}

export interface ISessionData {
    [key: string]: ISessionItem;
}

export interface ISessionsKeys {
    id: string;
    type: SessionType;
}

interface ISessions {
    items: ISessionsKeys[];
    current: string;
}

const longSession = State({}, 'auth-data', new LocalStorageStrategy()) as ISessionData;
const shortSession = State({}, 'auth-data', new SessionStorageStrategy()) as ISessionData;
export const pinSession = State({ current: '', token: '' }, 'auth-pin', new SessionStorageStrategy()) as ISessionItem;
export const sessions = State({ items: [], id: '' }, 'auth-sessions', new LocalStorageStrategy()) as ISessions;

export class SessionState {

    private _id: string;
    private _type: SessionType = SessionType.Long;

    constructor(id: string ) {
        this._id = id;
        const item = sessions.items.find((item) => item.id === id);
        if (item) {
            this._type = item.type;
        }
    }

    setType(type: SessionType) {
        this._type = type;
    }

    get(): ISessionItem {
        switch (this._type) {
            case SessionType.Long:
                return longSession[this._id] || {};
            case SessionType.Short:
                return shortSession[this._id] || {};
            default:
                return {} as ISessionItem;
        }
    }

    set (value: Partial<ISessionItem>): void {
        const fullValue = { ...this.get(), ...value };
        switch (this._type) {
            case SessionType.Long:
                longSession[this._id] = fullValue;
                break;
            case SessionType.Short:
                shortSession[this._id] = fullValue;
                break;
        }
    }
}

export function sessionEach(func: (item: ISessionsKeys, storage: ISessionData) => void): void {
    sessions.items.forEach((item) => {
        switch (item.type) {
            case SessionType.Long:
                func(item, longSession);
                break;
            case SessionType.Short:
                func(item, shortSession);
                break;
        }
    });
}
