
import { MutationTree, ActionTree, ActionContext } from "vuex";
import { State as StateFunc, CookieStrategy } from '@/utility/state';

export interface IRegisterState {
    name: string;
    email: string;
    username: string;
    password: string;
    repeatPassword: string;
    remember: string;
}

type StateItem = keyof IRegisterState;
class State implements IRegisterState {

    get name() {
        return this._state.name;
    };
    set name(value: string) {
        this._state.name = value;
    };
    get username() {
        return this._state.username;
    };
    set username(value: string) {
        this._state.username = value;
    };

    get email() {
        return this._state.email;
    };
    set email(value: string) {
        this._state.email = value;
    };

    get password() {
        return this._state.password;
    };
    set password(value: string) {
        this._state.password = value;
    };

    get remember() {
        return this._state.remember;
    };
    set remember(value: string) {
        this._state.remember = value;
    };

    repeatPassword = '';
    private _state;
    constructor() {
        this._state = StateFunc({
            name: '',
            email: '',
            username: '',
            password: '',
            remember: ''
        }, 'registerStore', new CookieStrategy({exp: 1000 * 60 * 5}));
    }
}

const mutations = <MutationTree<State>>{
    setValues(state: State, data: IRegisterState): void {
        Object.entries(data).forEach(([key, value]: [string, string]) => {
            const itemKey = key as StateItem;
            if (state[itemKey] !== undefined && typeof value === 'string') {
                state[itemKey] = value;
            }
        });
    }
};

const actions = <ActionTree<State, any>>{
    setValues(context: ActionContext<State, any>, data: IRegisterState) {
        context.commit('setValues', data);
    },
    clear(context: ActionContext<State, any>) {
        context.commit('setValues', {
            name: '',
            email: '',
            username: '',
            password: '',
            remember: ''
        });
    }
};

export default {
    namespaced: true,
    state: new State(),
    mutations: mutations,
    actions: actions
};
