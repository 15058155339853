import { ISubmitData } from "./registerValidation"

export function getMessageType(messages: string[]): Partial<ISubmitData> {
    const map: {[key: string]: keyof ISubmitData} = {
        'Некорректное имя.': 'name',
        'Логин занят.': 'username',
        'Адрес эл. почти занят.': 'email',
        'Не удалось отправить письмо на почту.': 'email',
        'Укажите имя пользователя почту и пароль.': 'name'
    };
    
    return messages.reduce((acc, message) => {
        const type = map[message];
        if (type) {
            acc[type] = message;
        }
        return acc;
    }, {} as Partial<ISubmitData>);
}
