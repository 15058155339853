<script lang="ts" setup>
    import { useStore } from 'vuex';
    import '@/theme/twExtended.less';
    import '@/theme/main.less';
    import { computed, ref, watch  } from 'vue';
    import { useRoute } from 'vue-router';
    import env from '@/config/env';

    const store = useStore();
    const route = useRoute();
    const loadingRef = ref(false);

    const isLoaded = computed((): boolean => {
        const meta = route.meta;
        if (meta && typeof meta.isLoaded === 'function') {
            const isLoaded = meta.isLoaded(store.state);
            loadingRef.value = loadingRef.value || isLoaded;
        }
        return loadingRef.value;
    });

    watch(isLoaded, () => {
        if (isLoaded.value) {
            document.querySelector('body')?.classList.toggle('not-loaded');
        }
    });
</script>

<template>
    <div
        class="tw-flex tw-flex-col tw-h-full"
        :class="(env.isMobile ? 'mobile-platform tw-no-highlights' : 'desktop-platform hovered')">
        <router-view v-if="isLoaded"/>
    </div>
</template>
