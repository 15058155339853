import { FilmTypes } from '@/utility/base';
import env from '@/config/env';
import { State } from '../state';
import { session } from '@/utility/auth/session';

export function debounce(func: Function, wait: number): IDebounceResult {
    let timeout: number;
    return function () {
        const args = arguments;

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...args);
        }, wait);
    };
}

export function getUrl(obj: string, endpoint: string, forceNewApi = false): string {
    const url = [
        env.proxy,
        forceNewApi ? env.hostForNewApiOnly : env.host,
        env.hostRoot,
        env.apiPath,
        obj,
        endpoint,
    ].map((item: string) => item.replace(/^\/|\/$/g, '')).filter(Boolean).join('/') + '.php';

    return /^https?:\/\//.test(url) ? url : `/${url}`;
}

export function getHeaders() {
    const token = session.getToken();
    return {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json;charset=utf-8',
        'Films-Device-Id': env.deviceId,
        'Films-Device-Os': JSON.stringify(env.os),
        'Films-Device-Browser': JSON.stringify(env.browser)
    };
}

export function getImageUrl(imageUrl: string): string {
    const url = [
        env.proxy,
        env.host,
        env.hostRoot,
        env.apiPath,
    ].map((item: string) => item.replace(/^\/|\/$/g, '')).filter(Boolean).join('/') + '/images.php';
    const normalUrl = /^https?:\/\//.test(url) ? url : `/${url}`;

    return `${normalUrl}?url=${imageUrl}`;
}

export function getProp(obj: IAny, path: string[]): any {
    let res = obj;
    for (let i = 0; i < path.length; i++) {
        const key = path[i];
        res = res && res[key];
        if (res === void 0) {
            break;
        }
    }
    return res;
}


export function updateFilmMeta(meta: string | undefined, data: Partial<IFilmMeta>): string {
    try {
        const parsed = JSON.parse(meta || '{}');
        const merged = { ...parsed, ...data };
        return JSON.stringify(merged);
    } catch (error) {
        return JSON.stringify(data);
    }
}

const translateMap: { [key: string]: string } = {
    ф: 'a', и: 'b', с: 'c', в: 'd', у: 'e', а: 'f', п: 'g', р: 'h', ш: 'i', о: 'j', л: 'k', д: 'l', ь: 'm', т: 'n',
    щ: 'o', з: 'p', й: 'q', к: 'r', ы: 's', е: 't', г: 'u', м: 'v', ц: 'w', ч: 'x', н: 'y', я: 'z',

    f: 'а', ',': 'б', d: 'в', u: 'г', l: 'д', t: 'е', '~': 'ё', ';': 'ж', p: 'з', b: 'и', q: 'й', r: 'к', k: 'л',
    v: 'м', y: 'н', j: 'о', g: 'п', h: 'р', c: 'с', n: 'т', e: 'у', a: 'ф', '[': 'х', w: 'ц', x: 'ч', i: 'ш', o: 'щ',
    '\'': 'э', '.': 'ю', z: 'я'
};

export function translate(str: string): string {
    return str.split('').map((char) => translateMap[char] || char).join('');
}

export function filmIsWatched(film: IFilm): boolean {
    let tvShowWatched = false;
    if (film.type === FilmTypes.TVShow) {
        const meta: IFilmMeta = JSON.parse(film.meta || '{}');
        tvShowWatched = meta.tvShowWatched || false;
    }

    return !!film.lastView || tvShowWatched;
}

interface ISearchItem {
    id: string;
    title: string;
    template: string;
    source: string;
    separator: string;
}

export interface ISearchState {
    selected: string,
    searches: ISearchItem[];
}

export function getSearchState(): ISearchState {
    const defaultTemplate = '$filmTitle';
    return State<ISearchState>({
        selected: 'google',
        searches: [
            { id: 'google', title: 'Google', source: 'https://google.ru/search?q=$searchValue', template: defaultTemplate, separator: '+' },
            { id: 'yandex', title: 'Yandex', source: 'https://yandex.ru/search/?text=$searchValue', template: defaultTemplate, separator: '+' }
        ]
    }, 'search-config-v2');
}

export function getSearchLink(str: string, state: ISearchState = getSearchState()): string {
    if (str && typeof str === 'string') {
        const searchTarget = /\$filmTitle/;
        const search: ISearchItem = state.searches.find(({id}) => id === state.selected) ||
            state.searches.find(({id}) => id === 'google') as ISearchItem;
        const { template, source } = search;

        const searchString = template.search(searchTarget) !== -1 ? template.replace(searchTarget, str) : str;

        return source.replace('$searchValue', encodeURI(searchString.replace(/\s/g, search.separator || ' ')));
    }
    return '';
}
