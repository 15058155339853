
import { getUrl, getHeaders } from '@/utility/functions';
import { StatusCodes, ReasonPhrases } from 'http-status-codes';
import { ApiMethods, HttpMethods } from './base';
import { session } from '@/utility/auth/session';

function needAuth() {
    session.remove();
    import('@/store').then((module) => {
        module.default.dispatch('main/login');
    });
}

export function crudQuery(bodyData: object, method: HttpMethods, endpoint: ApiMethods, forceNewApi = false): Promise<IResponse> {
    const token = session.getToken();

    const url = getUrl('endpoints', endpoint, forceNewApi);
    const body: { token?: string } = { ...bodyData, ...(token ? { token } : {}) };

    return fetch(url, {
        method,
        headers: {
            ...getHeaders()
        },
        body: JSON.stringify(body)
    }).then((res) => {
        if (res.status === StatusCodes.UNAUTHORIZED) {
            needAuth();
        }
        return res.json();
    }).then((json) => {
        return {
            data: json,
            error: json.error
        };
    }).catch(() => {
        return {
            error: true
        };
    });

}

export function crudQueryNew<T>(bodyData: object, method: HttpMethods, endpoint: ApiMethods, obj = 'endpoints'): Promise<IResponseNew<T>> {
    const token = session.getToken();;

    const url = getUrl(obj, endpoint);
    const body: { token?: string } = { ...bodyData, ...(token ? { token } : {}) };

    return fetch(url, {
        method,
        headers: {
            ...getHeaders()
        },
        body: JSON.stringify(body)
    }).then((res) => {
        if (res.status === StatusCodes.UNAUTHORIZED) {
            needAuth();
        }
        return res.json();
    }).catch((): IResponseNew<T> => {
        return {
            result: null as unknown as T,
            code: StatusCodes.INTERNAL_SERVER_ERROR,
            status: ReasonPhrases.INTERNAL_SERVER_ERROR,
            message: '',
            errors: []
        };
    });

}


/**
 * @returns { Promise<IResponse> }
 */
export function loadPublicFilm(data: string): Promise<IResponseNew<{ error: boolean, message: string, res: IFilm[]}>> {
    const filmData = data ? { data } : {};
    return crudQueryNew(filmData, HttpMethods.Read, ApiMethods.FilmsPublicPath);
};

/**
 * @returns { Promise<IResponse> }
 */
export function generatePublicFilmToken(ids: string[]): Promise<IResponseNew<{data: string}>> {
    const filmData = ids ? { ids } : {};
    return crudQueryNew(filmData, HttpMethods.Create, ApiMethods.FilmsPublicPath);
};

export function updateViewsList(changed: any): Promise<IResponse> {
    return crudQuery({ changed }, HttpMethods.Update, ApiMethods.ViewsListPath);
}

