import { createApp } from 'vue';
import router from './router';
import store from './store';
import App from './App.vue';

// add icons
import { default as Icon } from '@/components/core/Icon.vue';
import { default as Button } from '@/components/core/Button.vue';
import { default as FocusArea } from '@/components/core/FocusArea.vue';
import Page from '@/components/Page.vue'
import env from './config/env';
import { initApplication } from './utility/functions';

createApp(App)
    .use(store)
    .use(router)
    .component('mdi-icon', Icon)
    .component('button-rich', Button)
    .component('focus-area', FocusArea)
    .component('page', Page)
    .mount('#app');

initApplication();

if (env.isMobile) {
    import('./utility/virtualViewportFix').then(({virtualViewportFix}) => {
        virtualViewportFix();
    });
}
