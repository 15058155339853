<script lang="ts" setup>
    import env from '@/config/env';
    import { getKey } from '@/utility/base/key';
    import { computed, onMounted, onUnmounted, ref } from 'vue';

    const dialogRef = ref<HTMLDialogElement>(null as unknown as HTMLDialogElement);
    const popupId = ref(getKey());
    const position = ref('');

    const emit = defineEmits(['close']);
    const props = defineProps({
        size: {
            type: String,
            default: 'm'
        },
        closeOnOutsideClick: {
            type: Boolean,
            default: false
        }
    });

    const setPosition = (target?: HTMLElement) => {
        if (target) {
            const rect = target.getBoundingClientRect();
            const width = getDialog().clientWidth;
            const height = getDialog().clientHeight;
            const top = Math.max(0, Math.min(rect.bottom, window.innerHeight - height));
            const left = Math.max(0, Math.min(rect.left, window.innerWidth - width));
            position.value = `${top}px ${left}px`;
        } else {
            position.value = '';
        }
    };

    const getDialog = () => dialogRef.value;
    const showModal = (options?: IPopupOptions): void => {
        getDialog().showModal()
        setPosition(options?.target);
    };
    const show = (options?: IPopupOptions): void => {
        getDialog().show();
        setPosition(options?.target);
    };

    const close = () => getDialog().close();
    const closed = () => emit('close');
    const classes = computed(() => `dialog dialog-${ props.size } ${ position.value ? '' : 'center' }`);

    const outsideClick = (event: MouseEvent | TouchEvent) => {
        const target = event.target as HTMLElement;
        if (getDialog()?.open && !target?.closest(`#popup-${popupId.value}`)) {
            setTimeout(() => getDialog()?.close(), 0);
        }
    };

    const eventName =  env.isTouch ? 'touchstart' : 'mousedown';
    onMounted(() => props.closeOnOutsideClick && document.addEventListener(eventName, outsideClick, true));
    onUnmounted(() => props.closeOnOutsideClick && document.removeEventListener(eventName, outsideClick));

    defineExpose({
        showModal,
        show,
        close,
        get open() {
            return getDialog().open;
        }
    });
</script>

<template>
    <dialog @close="closed" :id="`popup-${popupId}`" ref="dialogRef" :class="classes" :style="{inset: position}">
        <slot/>
    </dialog>
</template>

<style lang="less" scoped>

    .dialog {
        z-index: 1;
        background-color: transparent;
        border-width: 0;
        width: 100%;
        max-width: 100vw;
        max-height: 100vh;
        border-radius: var(--border-radius);
        box-shadow: 3px 3px 14px 1px #0c0c0c;

        &:focus-visible {
            outline: none;
        }

        &-auto {
            width: min-content;
        }
    }

    .center {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media screen and (min-width: 380px) {
        .dialog {

            &-xs {
                width: 150px;
            }

            &-s {
                width: 200px;
            }

            &-m {
                width: 320px;
            }

            &-l {
                width: 400px;
            }

            &-xl {
                width: 500px;
            }
        }
    }

    ::backdrop {
        background-color: #000;
        opacity: 0.2;
    }

</style>
