export async function encode(token: string, password: string): Promise<string> {
    const pidCrypt = await loadPidCryptDeps();
    const aes = new pidCrypt.AES.CBC();
    return aes.encryptText(token, password, { nBits: 256 });
}

export async function decode(cryptToken: string, password: string): Promise<string> {
    const pidCrypt = await loadPidCryptDeps();
    const aes = new pidCrypt.AES.CBC();
    return aes.decryptText(cryptToken, password, { nBits: 256 });
}

function loadPidCryptDeps(): Promise<any> {
    return Promise.all([
        // @ts-ignore
        import('pidcrypt'),
        // @ts-ignore
        import('pidcrypt/seedrandom'),
        // @ts-ignore
        import('pidcrypt/aes_cbc')
    ]).then(([pidCrypt]) => {
        return pidCrypt.default;
    })
}