import { MutationTree, ActionTree, ActionContext } from 'vuex';
import { Sex } from '@/utility/base';
import { UserCrud } from '@/service/services';
import { session } from '@/utility/auth/session';

class State implements IUser {
    login: string = '';
    name: string = '';
    email: string = '';
    sex: Sex = Sex.Male;
    image: string = '';
    setState(data: Partial<IUser>) {
        this.login = typeof data.login === 'string' ? data.login : this.login;
        this.name = typeof data.name === 'string' ? data.name : this.name;
        this.email = typeof data.email === 'string' ? data.email : this.email;
        this.sex = data.sex === Sex.Male || data.sex === Sex.Female ? data.sex : this.sex;
        this.image = typeof data.image === 'string' ? data.image : this.image;
    }
}

const mutations = <MutationTree<State>>{
    setValues(state: State, data: Partial<IUser>): void {
        state.setState(data);
        session.updateSessionData(state);
    }
};

const actions = <ActionTree<State, any>> {
    load(context, username: string) {
        UserCrud.read({ }).then((data) => {
            if (!data.error && data.result) {
                context.commit('setValues', data.result?.[0]);
            }
        });
    },
    setValues(context: ActionContext<State, any>, data: Partial<IUser>) {
        context.commit('setValues', data);
    }
};

export default {
    namespaced: true,
    state: new State(),
    mutations: mutations,
    actions: actions
};
