import { router } from './routes';
import store from '@/store';
import { nextTick } from 'vue';
import { session } from '@/utility/auth/session';
import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

type TRouteLocationRaw = RouteLocationRaw | null;
type TAsyncRouteLocationRaw = Promise<TRouteLocationRaw>;

function getAuthRedirectData(to: RouteLocationNormalized, from: RouteLocationNormalized): TRouteLocationRaw {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!session.isAuth()) {
            return { name: 'Auth' };
        } else if (session.needPin()) {
            return { name: 'Pin', params: { rel: to.path }};
        }
    }
    return null;
    // go to wherever I'm going
}

function getPinCodeRedirectData(to: RouteLocationNormalized, from: RouteLocationNormalized): TRouteLocationRaw {
    if (to.name === 'Pin' && !session.needPin()) {
        return { name: 'Home'};
    }
    return null;
}

function loader(to: RouteLocationNormalized, from: RouteLocationNormalized): TAsyncRouteLocationRaw | TRouteLocationRaw {
    if (typeof to.meta.load === 'function') {
        return Promise.resolve(to.meta.load(store.state, to)).then(() => null);
    }
    return null;
}

router.beforeEach((to, from, next) => {
    if (hasUnsavedForm()) {
        return;
    }

    const resolver = [
        getAuthRedirectData,
        getPinCodeRedirectData,
        loader
    ].reduce((acc: TAsyncRouteLocationRaw, callback) => {
        return acc.then((res) => {
            return res ? res : callback(to, from);
        });
    }, Promise.resolve(null))

    resolver.then((res) => {
        if (res) {
            next(res);
        } else {
            next();
        }

        nextTick(() => {
            if (typeof to.meta.updateState === 'function') {
                to.meta.updateState(to, store.state);
            }
        });
    });
});

window.onpopstate = function(e) {
    if (hasUnsavedForm()) {
        window.history.go(1);
    }
}

function hasUnsavedForm(): boolean {
    // @ts-ignore
    return window.onbeforeunload && window.onbeforeunload(null) === '';
}
