<script lang="ts" setup>
    import { PropType, computed } from 'vue';
    import { IconSize } from '@/utility/base/icons';
    import { ButtonType, ButtonStyle } from '@/utility/base';

    const props = defineProps({
        icon: { type: String, default: '' },
        size: { type: String as PropType<IconSize>, default: IconSize.xl },
        type: { type: String as PropType<ButtonType>, default: ButtonType.Button },
        title: { type: String, default: '' },
        caption: { type: String, default: '' },
        color: { type: String, default: '' },
        buttonStyle: { type: String as PropType<ButtonStyle>, default: '' },
        clickable: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        rounded: { type: Boolean, default: false }
    });

    const style = computed((): ButtonStyle => {
        return props.disabled ? ButtonStyle.Disabled : props.buttonStyle || ButtonStyle.Transparent;
    });

    const iconClickable = computed((): boolean => {
        return props.clickable && style.value === ButtonStyle.Transparent;
    });

    const classes = computed((): {[key: string]: boolean} => {
        return {
            'icon-clickable': props.clickable !== false,
            'button-rounded': props.rounded,
            [`button-size-${props.size}`]: true,
            [`bg-${style.value}`]: true,
            [`bg-${style.value}-hovered`]: !props.disabled,
            [`text-for-${props.color || style.value}`]: true,
        };
    });

</script>

<template>
    <button
        :class="classes"
        :title="title"
        :disabled='disabled || clickable === false'
        :aria-label="caption || icon"
        :type="type"
        class="button tw-flex tw-items-center tw-justify-center">
        <mdi-icon
            v-if="icon"
            :icon="icon"
            :size="size"
            :clickable="iconClickable"
            :title="title"
            :colorStyle="buttonStyle"
            :disabled="disabled"
        />
        <span v-if="caption && !rounded" :class="{'tw-ml-4': !!icon}">
            {{ caption }}
        </span>
    </button>
</template>

<style lang="less">
.button {
    border-color: transparent;
    border-radius: 4px;

    &-rounded {
        border-radius: 50%;
    }

    &-clickable {
        cursor: pointer;
    }

    &-size {
        &-2xs {
            height: 14px;
        }
        &-xs {
            height: 16px;
        }
        &-s {
            height: 18px;
        }
        &-m {
            height: 20px;
        }
        &-l {
            height: 24px;
        }
        &-xl {
            height: 28px;
        }
        &-2xl {
            height: 36px;
        }
        &-3xl {
            width: 38px;
            height: 38px;
        }
        &-4xl {
            width: 42px;
            height: 42px;
        }
        &-5xl {
            width: 50px;
            height: 50px;
        }
        &-6xl {
            width: 58px;
            height: 58px;
        }
        &-7xl {
            width: 66px;
            height: 66px;
        }
        &-8xl {
            width: 74px;
            height: 74px;
        }
    }
}
</style>
