import { MutationTree, ActionTree } from 'vuex';
import { Collection } from '@/utility/collection';
import { filmIsWatched } from '@/utility/functions';
import { FilmTypes } from '@/utility/base';
import { search } from '@/utility/search';

export interface IFilmsCollectionState {
    items: Collection<IFilm, 'id'>;
    more: {
        before: boolean;
        after: boolean;
    }
}

interface INavigation {
    size: number;
    page: number;
    count: number;
}

interface IQuery {
    filter?: {
        views: string[];
        type: FilmTypes[];
        myRating: string[];
    };
    searchValue?: string;
    searchRegex?: boolean;
    nav?: INavigation;
}

class State implements IFilmsCollectionState {
    items = new Collection<IFilm, 'id'>();
    more = { before: false, after: false };
}

const mutations = <MutationTree<State>>{
    load(state: IFilmsCollectionState, data: IFilmsCollectionState) {
        state.items.clear();
        state.items.merge(data.items);
        state.more = data.more;
    }
};

const actions = <ActionTree<State, any>>{
    query(context, query: IQuery) {
        const films: IFilm[] = context.rootState.films.films;
        const toNumber = (value: string): number | void => {
            if (value) {
                return new Date(value).getTime() / 1000;
            }
        };

        const sorting = (film1: IFilm, film2: IFilm): number => {
            const view1 = toNumber(film1.lastView || film1.createDate);
            const view2 = toNumber(film2.lastView || film2.createDate);
            return view1 === view2 ? 0 : (view1 < view2 ? 1 : -1);
        };

        const filter = (film: IFilm) => {
            const searchMatch = query.searchValue ? search(query.searchValue, film.name, query.searchRegex) : true;
            const ratingMatch = !query.filter?.myRating?.length || query.filter?.myRating?.includes(film.myRating === '0' ? '0' : '1') ;
            const watchType = filmIsWatched(film) ? 'viewed' : 'notViewed';
            const viewMatch = !query.filter?.views?.length || query.filter?.views?.includes(watchType);
            const typeMatch = !query.filter?.type?.length || query.filter?.type?.includes(film.type as FilmTypes);
            return searchMatch && viewMatch && typeMatch && ratingMatch;
        };

        let items = films.sort(sorting).filter(filter);
        let before = false;
        let after = false;
        if (query.nav) {
            const start = query.nav.page * query.nav.size;
            const end = start + query.nav.size * query.nav.count;

            before = start > 0;
            after = items.length > end;
            items = items.slice(start, end);
        }

        return context.commit('load', {
            items,
            more: { before, after }
        });
    }
};

export default {
    namespaced: true,
    state: new State(),
    mutations: mutations,
    actions: actions
};
