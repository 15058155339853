
import { ApiMethods, HttpMethods } from '@/utility/base';
import { crudQueryNew } from '@/utility/service';

interface ISendMailParams {
    username: string;
    email: string;
    name: string;
    pass: string;
}

interface ISendMailResult {
    token: string;
}

interface ICheckUserDataParams {
    username: string;
    email: string;
}

interface ICheckUserDataResult {
    usernameExist: boolean;
    emailExist: boolean;
}

interface IRegisterParams {
    code: string;
    codeToken: string;
}

interface IRegisterResult {
    token: string;
}

export class Auth {

    // auth(data: Partial<T>): Promise<ICrudResult<string>> {
    //     return this._call<string>(data, HttpMethods.Create);
    // }

    checkUserData(data: ICheckUserDataParams): Promise<IResponseNew<ICheckUserDataResult>> {
        return this._call<ICheckUserDataResult>(data, HttpMethods.Read, 'checkUserData');
    }

    sendMail(data: ISendMailParams): Promise<IResponseNew<ISendMailResult>> {
        return this._call<ISendMailResult>(data, HttpMethods.Read, 'sendMail');
    }

    register(data: IRegisterParams): Promise<IResponseNew<IRegisterResult>> {
        return this._call<IRegisterResult>(data, HttpMethods.Read, 'register');
    }

    protected _call<TRes>(data: object, httpMethod: HttpMethods, method: string): Promise<IResponseNew<TRes>> {
        const patchedData = {...data, method};
        return crudQueryNew<TRes>(patchedData, httpMethod, ApiMethods.Auth, 'service');
    }
}
