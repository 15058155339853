import { AuthService } from "@/service/services";

export interface ISubmitData {
    name: string;
    email: string;
    username: string;
    password: string;
    repeatPassword: string;
};

export type SubmitDataKey = keyof ISubmitData;

export interface IValidateResult {
    message: string;
    target: SubmitDataKey;
}

export type IValidateFunction = (data: ISubmitData) => Promise<IValidateResult>;

export async function validateUser(data: ISubmitData): Promise<IValidateResult> {

    const validName = /^[a-zA-Zа-яА-Я\s]{2,}$/.test(data.name);

    if (!validName) {
        return {
            message: 'Некорректное имя',
            target: 'name'
        };
    }

    const validEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(data.email);

    if (!validEmail) {
        return {
            message: 'Некорректный адрес эл. почты',
            target: 'email'
        };
    }

    if (!/^[\w]{3,}$/.test(data.username)) {
        return {
            message: 'Имя пользователя должен быть не менее 3 букв английского алфавита.',
            target: 'username'
        };
    }

    return AuthService.checkUserData({
        username: data.username,
        email: data.email
    }).then((res) => {
        let message = '';
        let target: SubmitDataKey = 'email';
        if (res.result) {
            if (res.result.emailExist) {
                message = 'Пользователь с такой почтой уже существует.';
            }
            if (res.result.usernameExist) {
                message = 'Логин занят.';
                target = 'username';
            }
        }
        return { message, target };
    });
}

export async function validatePass(data: ISubmitData): Promise<IValidateResult> {

    const isEqual = data.password === data.repeatPassword;

    if (!isEqual) {
        return {
            message: 'Пароли не совпадают.',
            target: 'repeatPassword'
        };
    }

    const passPattern = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*()\-__+.]){1,}).{8,}$/;
    const valid = passPattern.test(data.password);
    const strongPassMessage = `Длина пароля должна быть не менее 8 символов, должен состоять из букв
            латинского алфавита (A-z), арабских цифр (0-9) и специальных символов.`;

    return {
        message: valid ? '' : strongPassMessage,
        target: 'password'
    };
}
