<template>
    <svg
        width="163"
        height="42"
        viewBox="0 0 163 42"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg">
        <path
            style="fill:currentColor;"
            d="M 2.4277344,3.046875 V 38.5 H 161.00586 V 3.046875 Z m 75.5761716,2 H 159.00586 V 36.5 H 78.003906 Z m -57.40625,9.693359 h 8.50586 v 2.097657 h -6.001954 v 2.9375 H 28.28125 V 21.875 h -5.179688 v 5.271484 h -2.503906 z m 10.494141,0 h 2.505859 v 12.40625 h -2.505859 z m 15.445312,0 h 3.75 l 2.251953,8.462891 2.22461,-8.462891 h 3.757812 v 12.40625 h -2.326172 v -9.765625 l -2.46289,9.765625 h -2.41211 l -2.455078,-9.765625 v 9.765625 h -2.328125 z m -10.486328,0.101563 h 2.50586 v 10.214844 h 6.228515 v 2.089843 h -8.734375 z" />
        <g style="fill:currentColor;">
            <path
                d="M 90.605509,27.147289 V 14.739761 h 2.437496 l 5.078115,8.285791 v -8.285791 h 2.32747 v 12.407528 h -2.513668 l -5.001943,-8.09113 v 8.09113 z" />
            <path
                d="m 102.58986,21.019697 q 0,-1.89583 0.56706,-3.182285 0.42317,-0.947915 1.15104,-1.701169 0.73632,-0.753254 1.60807,-1.117185 1.1595,-0.490885 2.67447,-0.490885 2.74218,0 4.38411,1.701169 1.65038,1.701168 1.65038,4.73111 0,3.004552 -1.63346,4.70572 -1.63346,1.692705 -4.36718,1.692705 -2.76757,0 -4.40103,-1.684241 -1.63346,-1.692705 -1.63346,-4.654939 z m 2.58138,-0.08464 q 0,2.107418 0.9733,3.199212 0.97331,1.083332 2.47135,1.083332 1.49804,0 2.45442,-1.074868 0.96484,-1.083331 0.96484,-3.24153 0,-2.132809 -0.93945,-3.182286 -0.93098,-1.049477 -2.47981,-1.049477 -1.54882,0 -2.49674,1.066404 -0.94791,1.057941 -0.94791,3.199213 z" />
            <path
                d="M 119.37303,27.147289 V 16.838716 h -3.68163 v -2.098955 h 9.86001 v 2.098955 h -3.67317 v 10.308573 z" />
            <path
                d="M 127.16794,27.147289 V 14.739761 h 9.19985 v 2.098955 h -6.69465 v 2.750645 h 6.22916 v 2.090491 h -6.22916 v 3.376947 h 6.93163 v 2.09049 z" />
            <path
                d="m 138.09435,23.110188 2.43749,-0.236979 q 0.22006,1.227211 0.88867,1.802731 0.67709,0.57552 1.81966,0.57552 1.21029,0 1.81966,-0.507812 0.61784,-0.516275 0.61784,-1.20182 0,-0.440104 -0.26237,-0.744791 -0.25391,-0.31315 -0.89714,-0.541665 -0.4401,-0.152344 -2.00585,-0.541666 -2.01432,-0.499348 -2.82682,-1.227211 -1.14257,-1.024087 -1.14257,-2.49674 0,-0.947915 0.5332,-1.768877 0.54166,-0.829425 1.54882,-1.261065 1.01563,-0.43164 2.44596,-0.43164 2.33593,0 3.51236,1.024087 1.1849,1.024086 1.24414,2.733718 l -2.5052,0.110026 q -0.16081,-0.956378 -0.69401,-1.371091 -0.52474,-0.423176 -1.58268,-0.423176 -1.09179,0 -1.70963,0.448567 -0.39779,0.28776 -0.39779,0.77018 0,0.440104 0.3724,0.753254 0.47396,0.397786 2.30208,0.829426 1.82812,0.431639 2.69986,0.897133 0.88021,0.457031 1.37109,1.261066 0.49935,0.795571 0.49935,1.972001 0,1.066404 -0.59245,1.997392 -0.59244,0.930988 -1.67577,1.388018 -1.08333,0.448567 -2.69987,0.448567 -2.35286,0 -3.61392,-1.083331 -1.26107,-1.091795 -1.50651,-3.173822 z" />
        </g>
    </svg>
</template>
